.footer{
    bottom: 0;
    position: fixed;
    display: flex;
    width: 100vw;
    padding-bottom: 20px;
}

.footer2{
    margin-top: 30px;
    display: flex;
    width: 100vw;
    padding-bottom: 20px;
}

.iconContainer{
    margin: 0 auto;
}

.iconColor{
    color: #ffffff !important;
}

.middleIconFooter{
    margin-left: 15px;
    margin-right: 15px;
}