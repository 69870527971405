.wrapper{
    background-color: #282c34;
    /*min-height: calc(100vh - 20px);*/
    padding: 20px 10px 0 10px;
    display: flex;
    flex-direction: column;
    max-width: 550px;
    margin: 0 auto;
}

.major{
    color: mintcream;
    text-align: center;
    margin-top: 0;
}

.name{
    color: mintcream;
    text-align: center;
}

.classOf{
    color: mintcream;
    text-align: center;
    margin-top: 0;
}

.langs{
    color: mintcream;
    text-align: center;
    margin-top: 0;
}
.grid{
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
}

.individ{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
}

.label{
    color: mintcream;
    text-align: center;
    margin-top: 0;
}