.wrapper{
    background-color: #282c34;
    /*min-height: calc(100vh - 20px);*/
    padding: 20px 10px 0 10px;
    display: flex;
    flex-direction: column;
    max-width: 550px;
    margin: 0 auto;
}

.cardContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}