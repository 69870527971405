.projWrapper{
    background-color: #282c34;
    /*min-height: calc(100vh - 20px);*/
    padding: 20px 10px 0 10px;
    display: flex;
    flex-direction: column;
    max-width: 550px;
    margin: 0 auto;
    animation: fadeIn 1s;
}

.slideImg{
    object-fit: contain !important;
    width: 100% !important;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
